/*
 * Ionic Variables and Theming
 * ----------------------------------------------------------------------------
 * For more information, please see
 * https://www.ionicframework.com/docs/theming/
 */

/*
 * Ionic Colors
 * ----------------------------------------------------------------------------
 * Named colors make it easy to reuse colors on various components.
 * It's highly recommended to change the default colors
 * to match your app's branding. Ionic provides nine layered colors
 * that can be changed to theme an app. Additional colors can be
 * added as well (see below). For more information, please see
 * https://www.ionicframework.com/docs/theming/colors
 *
 * To easily create custom color palettes for your app’s UI,
 * check out our color generator:
 * https://www.ionicframework.com/docs/theming/color-generator
 */
.alias {
  opacity: 1;
  font-weight: bold;
  padding-right: 10px;
  animation: slideInX 1s;
}

body.dark {
  --ion-toolbar-background: black;
  background-color: #000000;
  --ion-background-color: #121212;
}

toolbar-searchbar .dark {
  --ion-toolbar-background: black;
}

.search-divider {
  width: 1px;
  height: 28px;
  --min-height: 28px;
  margin-left: 8px;
  margin-right: 8px;
  --padding-start: 0;
  --inner-padding-end: 0;
  --background: var(--ion-color-step-550, #9f9f9f);
}

:root {
  /** primary **/
  --ion-color-primary: skyblue;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #d9edf7;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/

  --ion-color-tertiary: darkblue;
  --ion-color-tertiary-rgb: 76, 128, 185;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/*
 * Additional Ionic Colors
 * ----------------------------------------------------------------------------
 * In order to add colors to be used within Ionic components,
 * the color should be added as a class with the convention `.ion-color-{COLOR}`
 * where `{COLOR}` is the color to be used on the Ionic component.
 * For more information on adding new colors, please see
 * https://ionicframework.com/docs/theming/colors#adding-colors
 *
 * To generate the code for a new color, check out our new color creator:
 * https://ionicframework.com/docs/theming/colors#new-color-creator
 */

:root {
  --ion-color-favorite: #69bb7b;
  --ion-color-favorite-rgb: 105, 187, 123;
  --ion-color-favorite-contrast: #ffffff;
  --ion-color-favorite-contrast-rgb: 255, 255, 255;
  --ion-color-favorite-shade: #5ca56c;
  --ion-color-favorite-tint: #78c288;

  --ion-color-twitter: #1da1f4;
  --ion-color-twitter-rgb: 29, 161, 244;
  --ion-color-twitter-contrast: #ffffff;
  --ion-color-twitter-contrast-rgb: 255, 255, 255;
  --ion-color-twitter-shade: #1a8ed7;
  --ion-color-twitter-tint: #34aaf5;

  --ion-color-instagram: #5956d8;
  --ion-color-instagram-rgb: 89, 86, 216;
  --ion-color-instagram-contrast: #ffffff;
  --ion-color-instagram-contrast-rgb: 255, 255, 255;
  --ion-color-instagram-shade: #4e4cbe;
  --ion-color-instagram-tint: #6a67dc;

  --ion-color-vimeo: #23b6ea;
  --ion-color-vimeo-rgb: 35, 182, 234;
  --ion-color-vimeo-contrast: #ffffff;
  --ion-color-vimeo-contrast-rgb: 255, 255, 255;
  --ion-color-vimeo-shade: #1fa0ce;
  --ion-color-vimeo-tint: #39bdec;

  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59, 89, 152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;
}

.ion-color-favorite {
  --ion-color-base: var(--ion-color-favorite);
  --ion-color-base-rgb: var(--ion-color-favorite-rgb);
  --ion-color-contrast: var(--ion-color-favorite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
  --ion-color-shade: var(--ion-color-favorite-shade);
  --ion-color-tint: var(--ion-color-favorite-tint);
}

.ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter);
  --ion-color-base-rgb: var(--ion-color-twitter-rgb);
  --ion-color-contrast: var(--ion-color-twitter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
  --ion-color-shade: var(--ion-color-twitter-shade);
  --ion-color-tint: var(--ion-color-twitter-tint);
}

.ion-color-google {
  --ion-color-base: var(--ion-color-google);
  --ion-color-base-rgb: var(--ion-color-google-rgb);
  --ion-color-contrast: var(--ion-color-google-contrast);
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
  --ion-color-shade: var(--ion-color-google-shade);
  --ion-color-tint: var(--ion-color-google-tint);
}

.ion-color-instagram {
  --ion-color-base: var(--ion-color-instagram);
  --ion-color-base-rgb: var(--ion-color-instagram-rgb);
  --ion-color-contrast: var(--ion-color-instagram-contrast);
  --ion-color-contrast-rgb: var(--ion-color-instagram-contrast-rgb);
  --ion-color-shade: var(--ion-color-instagram-shade);
  --ion-color-tint: var(--ion-color-instagram-tint);
}

.ion-color-vimeo {
  --ion-color-base: var(--ion-color-vimeo);
  --ion-color-base-rgb: var(--ion-color-vimeo-rgb);
  --ion-color-contrast: var(--ion-color-vimeo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vimeo-contrast-rgb);
  --ion-color-shade: var(--ion-color-vimeo-shade);
  --ion-color-tint: var(--ion-color-vimeo-tint);
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-github {
  --ion-color-base: #211f1f;
  --ion-color-base-rgb: 33, 31, 31;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1d1b1b;
  --ion-color-tint: #373535;
}

.ion-color-instagram {
  --ion-color-base: #9537bc;
  --ion-color-base-rgb: 149, 55, 188;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #8330a5;
  --ion-color-tint: #a04bc3;
}

.list-ios {
  background-color: rgba(0, 0, 0, 0) !important;
}

.light .conversation-list {
  background-color: #e1e3eb !important;
}

.dark .conversation-list {
  background-color: #121212 !important;
}

.messages::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}


.message-input {
  border-radius: 10px;
  padding-left: 10px;
  padding: 10px;
  margin: 10px;
  background-color: clear;
}

.dark .message-input {
  border-radius: 10px black;
}

.hydrated {
  transition: height 0.2s ease-in-out;
}

.song-alias {
  color: skyblue;
  padding: 5px;
}

.dark .song-alias {
  color: var(--ion-color-tertiary-shade);
}

a {
  outline: none;
}

a img {
  outline: none;
}

img {
  border: 0;
}

.song-item {
  box-shadow: rgba(55, 55, 55, 0.5) 0px 0px 4px;
}

.light .song-title {
  --color: #444;
}

.dark .song-title {
  --color: #999;
}

.song-item .dark {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 12px;
}

.song-item-header .now-playing {
  --background: "#D6EBE0";
}

.dark .now-playing {
  --background: indigo !important;
}

.song-item-header {
  padding: 10px;
  --background: #d9edf7;
}

.dark .song-item-header {
  --background: midnightblue;
}

.light .song-item-content {
  background: white !important;
}

.dark .song-item-content {
  background: rgb(23, 23, 23);
}

.mention-alias {
  color: lightseagreen;
}

.mention-alias::after {
  content: " ";
  white-space: pre;
}

ion-title {
  font-weight: lighter;
}

ion-card-title {
  color: white;
  text-shadow: 0px -2px 5px rgb(20, 20, 20);
  text-shadow: 0px 2px 5px rgb(20, 20, 20);
  text-shadow: -2px 0px 5px rgb(20, 20, 20);
  text-shadow: 2px 0px 5px rgb(20, 20, 20);
}

.comment-widget {
  animation: fadein 0.5s;
  -moz-animation: fadein 0.5s;
  /* Firefox */
  -webkit-animation: fadein 0.5s;
  /* Safari and Chrome */
  -o-animation: fadein 0.5s;
}

.comment {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  line-height: 120%;
  color: #666;
}

.dark .comment {
  color: #888;
}

.comments {
  font-size: 13pt;
  padding-top: 17px;
}

ion-item {
  animation: fadein 0.1s;
  -moz-animation: fadein 0.1s;
  -webkit-animation: fadein 0.1s;
  -o-animation: fadein 0.1s;
}

ion-title {
  animation: fadein 0.1s;
  -moz-animation: fadein 0.1s;
  -webkit-animation: fadein 0.1s;
  -o-animation: fadein 0.1s;
}

ion-chip {
  animation: fadein 0.1s;
  -moz-animation: fadein 0.1s;
  -webkit-animation: fadein 0.1s;
  -o-animation: fadein 0.1s;
}

#login-page {
  animation: fadein 0.1s;
  -moz-animation: fadein 0.1s;
  -webkit-animation: fadein 0.1s;
  -o-animation: fadein 0.1s;
}

ion-text {
  animation: fadein 0.1s;
  -moz-animation: fadein 0.1s;
  -webkit-animation: fadein 0.1s;
  -o-animation: fadein 0.1s;
}

ion-toolbar {
  animation: fadein 0.1s;
  -moz-animation: fadein 0.1s;
  -webkit-animation: fadein 0.1s;
  -o-animation: fadein 0.1s;
}

ion-card {
  animation: fadein 0.1s;
  -moz-animation: fadein 0.1s;
  -webkit-animation: fadein 0.1s;
  -o-animation: fadein 0.1s;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: clear;
  color: #666;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideinX {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@-moz-keyframes slideinX {
  /* Firefox */
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slideinX {
  /* Safari and Chrome */
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@-o-keyframes slideinX {
  /* Opera */
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes slideinY {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

@-moz-keyframes slideinY {
  /* Firefox */
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideinY {
  /* Safari and Chrome */
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

@-o-keyframes slideinY {
  /* Opera */
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes growin {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@-moz-keyframes growin {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@-webkit-keyframes growin {
  /* Safari and Chrome */
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@-o-keyframes growin {
  /* Opera */
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.light .chill {
  --ion-background-color: aliceblue;
}

.light .inner-chill {
  --ion-background-color: white;
}

.dark .chill {
  --ion-background-color: #141440;
}

.dark .inner-chill {
  --ion-background-color: #333;
}

.dark .artist-wall {
  --ion-background-color: #121212;
}

.light .artist-wall {
  --ion-background-color: white;
}

.artist-wall {
  height: 100%;
  overflow: hidden;
  opacity: 1;
  -webkit-transition: background-image 1s ease-in-out;
  transition: background-image 1s ease-in-out;
}

.artist-bg {
  height: 100%;
  overflow: hidden;
  opacity: 1;
  background-image: url("/assets/img/void.png");
  -webkit-transition: background-image 1s ease-in-out;
  transition: background-image 1s ease-in-out;
}

.messages-widget {
  padding-bottom: 100px;
}

.dzu-dropzone {
  scrollbar-width: 0;
  overflow: hidden !important; /* Hide scrollbars */
  border: none !important;
}

.dzu-previewFileName {
  color: #519f6b !important;
}

.dzu-previewContainer {
  border: none !important;
}

.dzu-submitButtonContainer {
  display: none;
  border: none !important;
}

/*
 * Shared Variables
 * ----------------------------------------------------------------------------
 * To customize the look and feel of this app, you can override
 * the CSS variables found in Ionic's source files.
 * To view all of the possible Ionic variables, see:
 * https://ionicframework.com/docs/theming/css-variables#ionic-variables
 */

:root {
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;
}

/*
 * App iOS Variables
 * ----------------------------------------------------------------------------
 * iOS only CSS variables can go here
 */

/*
 * App Material Design Variables
 * ----------------------------------------------------------------------------
 * Material Design only CSS variables can go here
 */

/*
 * App Theme
 * ----------------------------------------------------------------------------
 * Ionic apps can have different themes applied, which can
 * then be further customized. These variables come last
 * so that the above variables are used by default.
 */
.no-scroll::-webkit-scrollbar {
  width: 0;
  display: none; /* Safari and Chrome */
}

/*
 * Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark {
  transition: all 2s ease-in-out;
  --inner-border-width: 0;
  --ion-color-primary: #8292ab;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #4f4fe6;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #519f6b;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #519f6b;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffda48;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
 * iOS Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;

  --ion-item-background: #181616;
}

/*
 * Material Design Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;
}
