.message-textarea {
  border-radius: 5px;
  padding: 5px 10px;
  margin: 10px 5px;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;

  & div {
    min-height: 40px !important;
  }

  & textarea {
    min-height: 40px !important;
  }
}

.message-item {
  display: flex;
  justify-content: space-between;
  padding: 3px 5px;

  .item-native {
    padding: 0 !important;
    border: 1px solid transparent;
  }
}

.message-label {
  padding: 5px;
}

.message-details {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.message-timestamp {
  font-size: 11px;
}

.sortWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 460px) {
    justify-content: center;
  }
}

.albumItem {
  margin: 12px 8px;
  max-width: 300px;
  width: calc(33.33% - 16px);

  @media (max-width: 768px) {
    width: calc(50% - 16px);
  }

  @media (max-width: 460px) {
    width: calc(100% - 16px);
  }
}

.albumArtistPageItem {
  margin: 12px 8px;
  max-width: 300px;
  width: calc(50% - 16px);

  @media (max-width: 460px) {
    width: calc(100% - 16px);
  }
}
