.avatarBorder {
  border: 2px solid var(--ion-color-primary);
}

.avatarLink {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-dark);
}

.centerColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.artistHeader {
  padding: 0;
  background: var(--ion-color-primary);
}
